import i18n from "@/plugins/i18n";
import store from "@/plugins/store/store";
import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

const routes = [
  {
    path: "/login",
    name: "login",
    component: () => import(/* webpackMode: "lazy" */ "@/views/Auth/Login"),
    meta: {
      title: "login"
    }
  },
  {
    path: "/logout",
    name: "logout",
    component: () => import(/* webpackMode: "lazy" */ "@/views/Auth/Logout"),
    meta: {
      title: "logout"
    }
  },
  {
    path: "/password/recover",
    name: "password-recover",
    component: () =>
      import(/* webpackMode: "lazy" */ "@/views/Auth/PasswordRecover"),
    meta: {
      title: "password-recover"
    }
  },
  {
    path: "/password/recover/:id",
    component: () =>
      import(/* webpackMode: "lazy" */ "@/views/Auth/PasswordChange"),
    meta: {
      title: "password-recover"
    }
  },
  {
    path: "/",
    component: () => import("@/views/Base"),
    meta: {
      title: "welcome"
    },
    children: [
      {
        path: "",
        name: "dashboard",
        component: () => import(/* webpackMode: "lazy" */ "@/views/Home"),
        meta: {
          title: "welcome",
          auth: true
        }
      },
      {
        path: "users",
        name: "users",
        component: () =>
          import(/* webpackMode: "lazy" */ "@/views/Users/Index"),
        meta: {
          title: "users",
          auth: true
        }
      },
      {
        path: "users/new",
        name: "users-new",
        component: () => import(/* webpackMode: "lazy" */ "@/views/Users/Form"),
        meta: {
          title: "users-.new",
          auth: true
        }
      },
      {
        path: "users/:id/edit",
        name: "users-edit",
        component: () => import(/* webpackMode: "lazy" */ "@/views/Users/Form"),
        meta: {
          title: "users-.edit",
          auth: true
        }
      },
      {
        path: "users/:id/details",
        name: "users-details",
        component: () =>
          import(/* webpackMode: "lazy" */ "@/views/Users/Details"),
        meta: {
          title: "users-.details",
          auth: true
        }
      },
      {
        path: "accounts/payroll",
        name: "account-payroll",
        component: () => import(/* webpackMode: "lazy" */ "@/views/Cards"),
        meta: {
          title: "payroll-cards",
          auth: true,
          capability: "transfer"
        }
      },
      {
        path: "accounts/deposits",
        name: "account-deposits",
        component: () => import(/* webpackMode: "lazy" */ "@/views/Cards"),
        meta: {
          title: "deposit-cards",
          auth: true,
          capability: "deposit"
        }
      },
      {
        path: "remittances",
        name: "remittances",
        component: () =>
          import(/* webpackMode: "lazy" */ "@/views/Remittances"),
        meta: {
          title: "remittances",
          auth: true
        }
      },
      {
        path: "remittance/:id",
        name: "remittance-details",
        component: () =>
          import(/* webpackMode: "lazy" */ "@/views/RemittanceDetails"),
        meta: {
          title: "remittances-.details",
          auth: true
        }
      },
      {
        path: "services",
        name: "services",
        component: () => import(/* webpackMode: "lazy" */ "@/views/Services"),
        meta: {
          title: "services",
          auth: true
        }
      },
      {
        path: "businesses",
        name: "businesses",
        component: () => import(/* webpackMode: "lazy" */ "@/views/Businesses"),
        meta: {
          title: "businesses",
          auth: true
        }
      },
      {
        path: "transactions/deposits",
        name: "deposits",
        component: () => import(/* webpackMode: "lazy" */ "@/views/Deposits"),
        meta: {
          title: "deposits",
          auth: true
        }
      },
      {
        path: "transactions/transfers",
        name: "transfers",
        component: () => import(/* webpackMode: "lazy" */ "@/views/Transfers"),
        meta: {
          title: "transfers",
          auth: true
        }
      },
      {
        path: "reports/profits",
        name: "profits",
        component: () => import(/* webpackMode: "lazy" */ "@/views/Profits"),
        meta: {
          title: "profits",
          auth: true
        }
      },
      {
        path: "reports/transactions",
        name: "transactions",
        component: () =>
          import(/* webpackMode: "lazy" */ "@/views/Transactions"),
        meta: {
          title: "transactions",
          auth: true
        }
      },
      {
        path: "reports/movements",
        name: "movements",
        component: () => import(/* webpackMode: "lazy" */ "@/views/Movements"),
        meta: {
          title: "movements",
          auth: true
        }
      },
      {
        path: "reports",
        name: "reports",
        component: () =>
          import(/* webpackMode: "lazy" */ "@/views/Users/Index"),
        meta: {
          title: "reports",
          auth: true
        }
      }
    ]
  },
  {
    path: "*",
    component: () =>
      import(/* webpackMode: "lazy" */ "@/views/errors/Error404"),
    meta: {
      title: "not_found"
    }
  }
  //End
];

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: routes,
  scrollBehavior() {
    window.scrollTo({ top: 0, left: 0 });
  }
});

router.beforeEach(async (to, from, next) => {
  store.dispatch("ENABLE_LOADING");

  const token = store.getters["auth/authToken"];
  const storedAuth = store.getters["auth/isLoggedIn"];
  if (to.name === "login" && token) {
    next({ name: "dashboard" });
  } else if (to.matched.some(record => record.meta.auth)) {
    if (token && !storedAuth) {
      await store.dispatch("auth/deleteAuthCookies", null, { root: true });
      await next({ name: "login", params: { redirectTo: to.fullPath } });
    } else if (to.name !== "login" && !token) {
      next({
        name: "login",
        params: { redirectTo: to.fullPath }
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

router.afterEach(to => {
  document.title = `${to.meta
    ? `${i18n.t(to.meta.title ? to.meta.title : to.name)} | `
    : ""} Accounting`;
});

export default router;
